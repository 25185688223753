export const onChange = /* GraphQL */ `
    subscription onChange($decision_id: Int!) {
        onChange(decision_id: $decision_id) {
            decision_id
            mutation
            old_row_id
            scores {
                choice_id
                factor_id
                viewpoint_id
                row_id
                m_score
                c_score
                class {
                    id
                    workspace_id
                    name
                    json
                }
                m_index
                c_index
            }
            rules {
                id
                name
                decision_id
                null_value_score
                is_index_rule
                index_uses_score
                is_range
                interpolate_range
                is_match
                is_distance
                is_aggregate
                aggregate_function
                no_match_score
                min_range_score
                min
                max
                threshold
                ranges {
                    id
                    rule_id
                    at_least
                    score
                    label
                    description
                    bigger_than
                }
                matches {
                    rule_id
                    match
                    score
                    label
                    description
                }
            }
            viewpoint_mappings {
                viewpoint_id
                factor_id
                score_rule_id
                index_rule_id
                set_id
                json
                order_str
                unit_id
                weight
                use_m_score
                use_m_index
            }
            values {
                choice_id
                factor_id
                value
                enum_value_id
                row_id
                json
            }
            factors {
                id
                decision_id
                description
                parent_id
                owner
                name
                json
                order_str
                is_group
                is_table
                is_column
                format
                suffix
                value_type
                enum_id
                m_score
                m_index
                score_rule_id
                index_rule_id
                set_id
                weight
                unit_id
                min
                max
            }
            rule_matches {
                rule_id
                match
                score
                label
                description
            }
            rule_ranges {
                id
                rule_id
                at_least
                score
                bigger_than
                label
                description
            }
            viewpoints {
                id
                name
                decision_id
                created
                updated
                owner
                description
            }
            enums {
                id
                decision_id
                name
                values {
                    id
                    enum_id
                    value
                }
            }
            enum_values {
                id
                enum_id
                value
            }
            choices {
                id
                name
                decision_id
                description
                owner
                created
                updated
            }
            tabs {
                id
                decision_id
                title
                json
                edit_flags
                display_flags
                filter_type
                row_type
                column_type
                default_filter_id
                type
                description
            }
            tab_factors {
                tab_id
                factor_id
            }
            tab_viewpoints {
                tab_id
                viewpoint_id
            }
            tab_choices {
                tab_id
                choice_id
            }
            app_tabs {
                app_id
                tab_id
                order_str
            }
            apps {
                id
                decision_id
                title
                description
                header
                json
                image
                owner
                created
                updated
            }
        }
    }
`;

export const onCompanyChange = /* GraphQL */ `
    subscription onCompanyChange($company_id: Int!) {
        onCompanyChange(company_id: $company_id) {
            company_id
            mutation
            workspaces {
                id
                name
                json
                description
                owner
                created
                updated
            }
        }
    }
`;

export const onWorkspaceChange = /* GraphQL */ `
    subscription onWorkspaceChange($workspace_id: Int!) {
        onWorkspaceChange(workspace_id: $workspace_id) {
            workspace_id
            mutation
            classes {
                id
                workspace_id
                name
                description
                json
            }
            class_sets {
                id
                workspace_id
                name
                mappings {
                    set_id
                    class_id
                    index
                    score
                    class {
                        id
                        workspace_id
                        name
                        json
                    }
                    json
                    fail
                }
                json
            }
            class_mappings {
                set_id
                class_id
                index
                score
                class {
                    id
                    workspace_id
                    name
                    json
                }
                json
                fail
            }
        }
    }
`;

export const onThreadChange = /* GraphQL */ `
    subscription onThreadChange($decision_id: Int!) {
        onThreadChange(decision_id: $decision_id) {
            id
            factor_id
            decision_id
            choice_id
            row_id
            viewpoint_id
            author_id
            created
            name
            description
            resolved
            count
        }
    }
`;

export const onCommentChange = /* GraphQL */ `
    subscription onCommentChange($thread_id: Int!) {
        onCommentChange(thread_id: $thread_id) {
            id
            thread_id
            author_id
            created
            updated
            comment
            reply_to
            num_replies
            user_name
            mutation
        }
    }
`;
