import { render, staticRenderFns } from "./CommentTree.vue?vue&type=template&id=0d958b7e&scoped=true&"
import script from "./CommentTree.vue?vue&type=script&lang=ts&"
export * from "./CommentTree.vue?vue&type=script&lang=ts&"
import style0 from "./CommentTree.vue?vue&type=style&index=0&id=0d958b7e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d958b7e",
  null
  
)

export default component.exports