
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { API } from "aws-amplify";
import { getModule } from "vuex-module-decorators";
import { onCommentChange } from "@/graphql/subscriptions";
import { Comment } from "@/graphql/API";
import Observable from "zen-observable-ts";
import { GraphQLResult } from "@aws-amplify/api";
import { OnCommentChange } from "@/graphql/custom";
import Comments from "@/store/modules/Comments";
import CommentList from "@/components/comments/CommentList.vue";

const commentModule = getModule(Comments);

@Component({
    components: {
        CommentList,
    },
})
export default class CommentTree extends Vue {
    @Prop()
    threadId!: number;

    @Prop()
    factorId!: number;

    @Prop()
    choiceId!: number;

    @Prop()
    replyId!: number;

    @Prop({ type: String, default: "desc" })
    order!: string;

    private loading = false;
    private internalComments: Comment[] = [];

    private commentSub: any = null;

    private otherComments: Comment[] = [];

    get factorChoiceIndex(): string {
        return `${this.choiceId ? this.choiceId : "null"}-${
            this.factorId ? this.factorId : "null"
        }-${this.threadId ? this.threadId : "null"}`;
    }

    get comments(): Comment[] {
        if (this.threadId && commentModule.keyedComments[this.threadId]) {
            return commentModule.keyedComments[this.threadId];
        } else {
            return [];
        }
    }

    get otherCommentTree(): { [id: number]: Comment[] } | null {
        return commentModule.currOtherComments;
    }

    get commentTree(): { [id: number]: Comment[] } | null {
        return commentModule.currCommentTree;
    }

    private toggleReply(id: number | null): void {
        this.$emit("toggle-reply", id);
    }

    private async unSubComments(): Promise<void> {
        if (this.commentSub != null) {
            this.commentSub.unsubscribe();
        }
    }

    private async initCommentSub(thread_id: number): Promise<void> {
        this.commentSub = (
            API.graphql({
                query: onCommentChange,
                variables: {
                    thread_id,
                },
            }) as Observable<unknown>
        ).subscribe({
            next: (pay: {
                provider: unknown;
                value: GraphQLResult<OnCommentChange>;
            }) => {
                const comment = pay.value.data?.onCommentChange;
                if (comment) {
                    commentModule.subscribeComment(comment);
                }
            },
            error: (error: any) => {
                console.log("Comment sub Error!")
                console.log(error);
            },
        });
    }

    mounted(): void {
        this.fetchComments();
        this.fetchOtherComments();
    }

    unmounted(): void {
        this.unSubComments();
    }

    @Watch("threadId")
    private async fetchComments(): Promise<void> {
        this.unSubComments();
        if (this.threadId) {
            commentModule.changeCurrThreadId(this.threadId);
            this.loading = true;
            this.internalComments = await commentModule.fetchComments(
                this.threadId
            );
            await this.initCommentSub(this.threadId);
            this.loading = false;
        } else {
            commentModule.changeCurrThreadId(null);
        }
    }

    @Watch("factorChoiceIndex")
    async fetchOtherComments(): Promise<void> {
        if (this.factorId && this.choiceId) {
            this.otherComments = await commentModule.fetchOtherComments({
                thread_id: this.threadId,
                factor_id: this.factorId,
                choice_id: this.choiceId,
            });
        }
    }
}
